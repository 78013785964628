<template>
    
    <v-row justify="center" align="center" style="height: 100%">
        <v-col cols="6" class="py-0">

            <v-card color="transparent" elevation="0">
                <v-card-text class="display-1  mb-5">{{ user.profile.name }}</v-card-text>

                <v-card-text class="text-start pt-5">
                    <span class="primary--text font-weight-light">First name</span>
                    <v-divider class="mb-3"></v-divider>
                    <span class=" title">{{ user.profile.first_name }}</span>
                </v-card-text>

                <v-card-text class="text-start">
                    <span class="primary--text font-weight-light">Last name</span>
                    <v-divider class="mb-3"></v-divider>
                    <span class=" title">{{ user.profile.last_name }}</span>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>
    
</template>

<script>
    export default {
        name: "ViewAccount",
        props: {
            user: Object
        },
    }
</script>

<style scoped>

</style>